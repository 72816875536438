import './app.css';
import React from 'react';
import {createRoot, hydrateRoot} from 'react-dom/client';
import CommonProvider from '../../common/resources/client/core/common-provider';
import * as Sentry from '@sentry/react';
import {rootEl} from '../../common/resources/client/core/root-el';
import {getBootstrapData} from '../../common/resources/client/core/bootstrap-data/use-backend-bootstrap-data';
import {ignoredSentryErrors} from '../../common/resources/client/errors/ignored-sentry-errors';
import type {GetArticleResponse} from '@app/help-center/articles/use-article';
import AppRoutes from '../../resources/client/app-routes';
import {BrowserRouter} from 'react-router-dom';
import {PurchaseCode} from '../../resources/client/agent/purchase-code';
import type {AfterReplyAction} from '../../resources/client/agent/agent-ticket-page/use-after-reply-action';
import {
    CustomerNewTicketPageConfig
} from '../../resources/client/help-center/tickets/customer-new-ticket-page/customer-new-ticket-page-config';
import {GetCategoryResponse} from '../../resources/client/help-center/categories/use-category';
import {initSearchTermLogger} from '../../resources/client/help-center/search/use-search-term-logger';
import {FetchCustomPageResponse} from '../../common/resources/client/custom-page/use-custom-page';
import {SearchArticlesResponse} from '../../resources/client/help-center/search/use-search-articles';
import {Tag} from '@common/tags/tag';
import {
    ImapConnectionCredentials
} from '../../resources/client/admin/settings/incoming-email/imap-connection-credentials';
import {StrictMode} from 'react';

declare module '@common/http/value-lists' {
    interface FetchValueListsResponse {
        //
    }
}

declare module '@common/core/bootstrap-data/bootstrap-data' {
    interface BootstrapData {
        loaders?: {
            landingPage?: any;
            articlePage?: GetArticleResponse;
            updateArticle?: GetArticleResponse;
            categoryPage?: GetCategoryResponse;
            updateCategory?: GetCategoryResponse;
            customPage?: FetchCustomPageResponse;
            searchArticles?: SearchArticlesResponse;
        };
    }
}

declare module '@common/core/settings/settings' {
    interface Settings {
        homepage?: {
            type?: string;
            value?: string;
        };
        ads?: {
            disable?: boolean;
        };
        landing?: {
            show_footer?: boolean;
            show_public_tickets?: boolean;
            hide_small_categories?: boolean;
            header?: {
                variant?: 'simple' | 'colorful';
                title?: string;
                subtitle?: string;
                placeholder?: string;
                background?: string;
                backgroundRepeat?: string;
                backgroundPosition?: string;
                backgroundSize?: string;
            };
            content?: {
                variant?: 'articleGrid' | 'multiProduct';
            };
        };
        hc?: {
            newTicket?: {
                appearance?: CustomerNewTicketPageConfig;
            };
        };
        article?: {
            hide_new_ticket_link?: boolean;
        };
        replies?: {
            after_reply_action?: AfterReplyAction;
            create_from_emails?: boolean;
        };
        envato: {
            enable: boolean;
            require_purchase_code: boolean;
            active_support: boolean;
            filter_search: boolean;
        };
        tickets?: {
            log_activity?: boolean;
            create_from_emails?: boolean;
        };
        incoming_email?: {
            imap?: {
                connections?: ImapConnectionCredentials[];
            };
            gmail?: {
                enabled?: boolean;
            };
            api?: {
                enabled?: boolean;
            };
        };
    }
}

declare module '@common/auth/user' {
    interface User {
        purchase_codes?: PurchaseCode[];
        tags?: Tag[];
        secondary_emails?: { address: string }[];
        description?: string;
        details?: {
            details?: string;
            notes?: string;
        };
    }
}

const data = getBootstrapData(); //window.bootstrapData
const sentryDsn = data.settings.logging.sentry_public;
if (sentryDsn && import.meta.env.PROD) {
    Sentry.init({
        dsn: sentryDsn,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: 0.2,
        ignoreErrors: ignoredSentryErrors,
        release: data.sentry_release,
    });
}

const app = (
    // <StrictMode>
    <BrowserRouter basename={data.settings.html_base_uri}>
        <CommonProvider>
            <AppRoutes/>
        </CommonProvider>
    </BrowserRouter>
    // </StrictMode>
);

if (data.rendered_ssr) {
    hydrateRoot(rootEl, app);
} else {
    createRoot(rootEl).render(app);
    initSearchTermLogger();
}
