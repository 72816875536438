import useThemeSelector from './theme-selector-context';

export default function useIsDarkMode(): boolean {
  const {selectedTheme} = useThemeSelector();

  if(selectedTheme === undefined){
    return false;
  }

  return selectedTheme.is_dark ? selectedTheme.is_dark : false;
}
