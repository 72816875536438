import {ColumnConfig} from '@common/datatable/column-config';
import {Ticket} from '@app/agent/ticket';
import {Trans} from '@common/i18n/trans';
import {FormattedRelativeTime} from '@common/i18n/formatted-relative-time';
import React from 'react';

export const PublicTicketTableColumns: ColumnConfig<Ticket>[] = [
    {
        key: 'subject',
        visibleInMode: 'all',
        header: () => <Trans message="Subject" />,
        body: ticket => ticket.subject,
        width: 'flex-3 min-w-200',
    },
    {
        key: 'replies_count',
        header: () => <Trans message="Replies count"/>,
        // hideHeader: true,
        width: 'w-90',
        body: ticket => (
            <div className="w-max rounded border bg-alt px-6 text-xs font-normal text-muted">
                {ticket.replies_count || 1}
            </div>
        ),
    },
    {
        key: 'id',
        allowsSorting: false,
        header: () => <Trans message="Id" />,
        width: 'w-90',
        body: ticket => `#${ticket.id}`,
    },
    {
        key: 'Created',
        allowsSorting: false,
        header: () => <Trans message="Created" />,
        width: 'w-144',
        body: ticket => <FormattedRelativeTime date={ticket.created_at} />,
    },
    {
        key: 'updated_at',
        allowsSorting: false,
        header: () => <Trans message="Last updated" />,
        width: 'w-144',
        body: ticket => <FormattedRelativeTime date={ticket.updated_at} />,
    },
];
