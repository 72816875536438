import React, {Fragment, useRef} from 'react';
import {Trans} from '@common/i18n/trans';
import useTickets from '@app/agent/agent-ticket-page/user-details-sidebar/requests/use-tickets';
import Table from '@common/ui/tables/table';
import useNavigate from '@common/utils/hooks/use-navigate';
import {TextField} from '@common/ui/forms/input-field/text-field/text-field';
import useTrans from '@common/i18n/use-trans';
import message from '@common/i18n/message';
import {SearchIcon} from '@common/icons/material/Search';
import {useSearchParams} from 'react-router-dom';
import DataTableEmptyStateMessage from '@common/datatable/page/data-table-emty-state-message';
import searchImage from '@app/agent/agent-search-page/search.svg';
import Skeleton from '@common/ui/skeleton/skeleton';
import {AnimatePresence, m} from 'framer-motion';
import {opacityAnimation} from '@common/ui/animation/opacity-animation';
import DataTablePaginationFooter from '@common/datatable/data-table-pagination-footer';
import {
    PublicTicketTableColumns
} from '@app/help-center/tickets/public-ticket-list/public-ticket-table-columns';


const defaultPage = '1';
const defaultPerPage = '20';

export default function PublicTicketList() {
    return (
        // <div>
        <div className="container mx-auto px-24 pb-48">
            <div className="mb-34 flex items-start justify-between gap-12">
                <h2 className="text-3xl font-semibold">
                    <Trans message="Public tickets"/>
                </h2>
            </div>
            <TicketTable/>
        </div>
        // </div>
    );
}

function TicketTable() {
    const [searchParams, setSearchParams] = useSearchParams();
    const {trans} = useTrans();
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const query = useTickets({
        status: 'publish',
        query: searchParams.get('query'),
        tagId: searchParams.get('tagId'),
        page: searchParams.get('page') || defaultPage,
        perPage: searchParams.get('perPage') || defaultPerPage,
    });
    const data = query.data?.pagination?.data || [];
    const isFiltering =
        !!searchParams.get('query') || !!searchParams.get('tagId');

    const setSearchQuery = () => {
        setSearchParams(prev => {
            if (inputRef.current?.value) {
                prev.set('query', inputRef.current.value);
            } else {
                prev.delete('query');
            }
            return prev;
        });
    };

    const content = !data.length ? (
        <StateMessage isFiltering={isFiltering}/>
    ) : (
        <Fragment>
            <Table
                columns={PublicTicketTableColumns}
                data={data}
                enableSelection={false}
                onAction={item => navigate(`/hc/tickets/${item.id}`)}
                cellHeight="h-60"
            />
            <DataTablePaginationFooter
                className="mt-12"
                query={query}
                onPageChange={page =>
                    setSearchParams(prev => {
                        prev.set('page', page.toString());
                        return prev;
                    })
                }
            />
        </Fragment>
    );

    return (
        <Fragment>
            <form
                className="mb-34 items-end justify-between gap-24 md:flex"
                onSubmit={e => {
                    e.preventDefault();
                    setSearchQuery();
                }}
            >
                <TextField
                    className="flex-auto max-md:mb-24"
                    inputRef={inputRef}
                    defaultValue={searchParams.get('query') || ''}
                    onBlur={() => setSearchQuery()}
                    placeholder={trans(message('Search ticket'))}
                    startAdornment={<SearchIcon/>}
                />
            </form>
            <AnimatePresence initial={false} mode="wait">
                {query.isLoading ? <Skeletons/> : content}
            </AnimatePresence>
        </Fragment>
    );
}

interface StateMessageProps {
    isFiltering: boolean;
}

function StateMessage({isFiltering}: StateMessageProps) {
    return (
        <DataTableEmptyStateMessage
            isFiltering={isFiltering}
            size="sm"
            className="mt-48"
            image={searchImage}
            title={<Trans message="There are no tickets yet"/>}
            filteringTitle={
                <Trans message="No requests match your search query or filters"/>
            }
        />
    );
}

function Skeletons() {
    return (
        <m.div key="skeletons" {...opacityAnimation}>
            <Skeleton size="h-36" variant="rect" className="mb-12"/>
            <Skeleton size="h-54" variant="rect" className="mb-12"/>
            <Skeleton size="h-54" variant="rect"/>
        </m.div>
    );
}
